import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddCustomer from './components/AddCustomer'
import Menu from './components/Menu'


import {addCustomerAction} from './actions/addCustomerAction'
import Customers from './components/Customers'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Profile from './components/profile'

const mapDispatchToProps = dispatch =>({
  addCustomerAction : ()=> dispatch(addCustomerAction())
})

const mapStateToProps = state =>({
  ...state
})


class App extends Component {
  render() {
    return (
      <Router>
        <Menu />
        <Switch>
          <Route path='/' exact component={Customers}/>
          <Route path='/add-customer' component={AddCustomer}/>
          <Route path='/customers' component={Customers}/>
          <Route path='/editProfile' component={Profile}/>
        </Switch>
        {/* <Customers /> */}
        {/* <AddCustomer /> */}
      </Router>
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);

