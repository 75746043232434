import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

export default function store(initialState={}){
    return createStore(
        rootReducer,
        initialState,
        applyMiddleware(thunk)
    )
}