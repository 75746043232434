import React, { Component } from 'react'
import axios from '../axios/index';
import { Countries } from '../utils/Countries';
import { Currency } from '../utils/Currency'

export class Profile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            states: [],
            userDetails: {},
            fields: {},
            errors: {}
        }
    }
    handleValidation() {
        let fields = this.state.userDetails;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["firstname"]) {
            formIsValid = false;
            errors["firstname"] = "First name cannot be empty";
        }
        if (!fields["lastname"]) {
            formIsValid = false;
            errors["lastname"] = "Last name cannot be empty";
        }
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = " Email cannot be empty";
        }
        if (!fields["password2"]) {
            formIsValid = false;
            errors["password2"] = "Confirm password cannot be empty";
        }
        if (!fields["addressline1"]) {
            formIsValid = false;
            errors["addressline1"] = "Adrress field cannot be empty";
        }
        if (!fields["addressline2"]) {
            formIsValid = false;
            errors["addressline2"] = "Adrress field cannot be empty";
        }
        if (!fields["companySize"]) {
            formIsValid = false;
            errors["companySize"] = "Company size field cannot be empty";
        }
        // if(!fields["country"]){
        //    formIsValid = false;
        //    errors["country"] = "Cannot be empty";
        // }
        if (!fields["state"]) {
            formIsValid = false;
            errors["state"] = "State cannot be empty";
        }
        if (!fields["city"]) {
            formIsValid = false;
            errors["city"] = "City cannot be empty";
        }
        if (!fields["postalcode"]) {
            formIsValid = false;
            errors["postalcode"] = "Postal code cannot be empty";
        }

        if (fields["email"]) {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    componentDidMount() {
        axios.get('/api/customers/profile?email=anilkumar51@gmail.com', {
            headers: {
                Authorization: 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwZDQ1NTFjMGY5MmZhMTI2Y2RlMGI4NSIsImVtYWlsIjoiYW5pbGt1bWFyNTJAZ21haWwuY29tIiwiYWRkcmVzc2xpbmUxIjoid2hpdGUgaG91c2UiLCJhZGRyZXNzbGluZTIiOiJiZWd1bXBldCIsInBvc3RhbGNvZGUiOiI1MDAwODIiLCJjaXR5IjoiSHlkZXJhYmFkIiwic3RhdGUiOiJUZWxhbmdhbmEiLCJjb3VudHJ5IjoiSW5kaWEiLCJsb2dnZWRpbklQIjoiIiwid2Vic2l0ZSI6Ind3dy5hcG95YXIuZXUiLCJiYW5rZGV0YWlscyI6e30sInZhdG51bWJlciI6IjY0NjU0MTMiLCJjb21wYW55U2l6ZSI6IjUxLTEwMCIsImN1cnJlbmN5IjoiSU5SIiwicGFydG5lcnJlZkNvZGUiOiIgNDM0MjM0IiwiaWF0IjoxNjI0NTM4NTY5LCJleHAiOjE2NTYwOTU0OTV9.mgu8WClHbBPggKGo3DBElN79LtjlQAVDo8dPWPOkO7E'
            }
        })
            .then(res => {
                this.setState({
                    userDetails: res.data,
                    bankname: res.data.bankdetails.bankname,
                    accountNumber: res.data.bankdetails.accountNumber,
                    ifscCode: res.data.bankdetails.ifscCode
                })
            })
        // this.setBankDetails()
    }
    // setBankDetails() {
    //     if (this.state.userDetails.email !== '') {
    //         // console.log('anil', this.state.userDetails)
    //     }
    // }
    onChange = (e) => {
        let fields = this.state.userDetails;
        fields[e.target.name] = e.target.value;
        this.setState({ fields });
        this.setState({
            userDetails: { ...this.state.userDetails, [e.target.name]: e.target.value }
        })
    }
    onChangeBankDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeCountry = (e) => {
        const data = e.target.value.split(",")
        this.setState({
            states: data
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.handleValidation()
        const customer = {
            email: this.state.userDetails.email,
            password: this.state.userDetails.password,
            password2: this.state.userDetails.password2,
            fkcustomerid: this.state.userDetails.fkcustomerid,
            compnayName: this.state.userDetails.compnayname,
            firstName: this.state.userDetails.firstname,
            lastName: this.state.userDetails.lastname,
            addressline1: this.state.userDetails.addressline1,
            addressline2: this.state.userDetails.addressline2,
            postalcode: this.state.userDetails.postalcode,
            city: this.state.userDetails.city,
            state: this.state.userDetails.state,
            country: this.state.userDetails.country,
            loggedinIP: this.state.userDetails.loggedinIP,
            website: this.state.userDetails.website,
            accountNumber: this.state.accountNumber,
            bankname: this.state.bankname,
            ifscCode: this.state.ifscCode,
            vatnumber: this.state.userDetails.vatnumber,
            companySize: this.state.userDetails.companySize,
            currency: this.state.userDetails.currency,
            partnerrefCode: this.state.userDetails.partnerrefCode
        }
        if(Object.keys(this.state.errors).length !== 0 && this.state.errors.constructor === Object) {
        axios.post('/api/customers/updateProfile', customer)
            .then(res => {
                console.log(res)
                console.log(res.data)
            })
        }
    }

    render() {
        console.log('state', this.state.userDetails)
        return (
            <div>
                <div className="container">
                    <div className="row" style={{ marginBottom: "0px" }}>
                        <div className="container grid-container" style={{ marginTop: "60px" }}>
                            <div className="col s12">
                                <h4 style={{ marginTop: "10px" }}>Edit Profile</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <form className="col s12" noValidate>
                            <div className="row">
                                <div className="col s12 module">
                                    {/* <div className="row profile-container" style={{ display: "none" }}>
                                        <div className="input-field col s12 profile-pic-container">

                                            <img id="profile-pic" />
                                            <input type="file" name="file" title="Update profile picture" onChange={this.onChangeHandler} style={{ width: "100%", height: "100%", position: "absolute", zIndex: 101 }} />
                                            <button className="right edit-btn" title="Update profile picture"></button>
                                            <button className="right del-btn" onClick={this.removeProfilePic} title="Remove profile picture"></button>
                                        </div>
                                        <div className="profile-intro-text">These details will be provide to our Service Managers and Potential Clients you need to fully complete your profile so we can allocate leads for you and/or your company to place bids.
                                        </div>
                                        <div className="input-field col s12 profile-pic-screenname">
                                            <h5 style={{ textTransform: "uppercase" }}>User Name</h5>
                                        </div>
                                    </div> */}


                                    <div className="row">
                                        <div className="input-field col s6">
                                            <input id="first_name" type="text" name="firstname" className="validate" onChange={this.onChange}
                                                value={this.state.userDetails.firstname} required />

                                            <label htmlFor="first_name" className="active">First Name <span className="red-text">*</span></label>
                                            <span className="red-text error-text">{this.state.errors.firstname}</span>
                                        </div>
                                        <div className="input-field col s6">
                                            <input id="last_name" type="text" name="lastname" className="validate" onChange={this.onChange}
                                                value={this.state.userDetails.lastname} required />

                                            <label htmlFor="last_name" className="active">Last Name <span className="red-text">*</span></label>
                                            <span className="red-text error-text">{this.state.errors.lastname}</span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="email" type="email" name="email" className="validate" onChange={this.onChange}
                                                value={this.state.userDetails.email} required disabled />

                                            <label htmlFor="email" className="active">Email Id <span className="red-text">*</span></label>
                                            <span className="red-text error-text">{this.state.errors.email}</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="col s12 module">
                                    <h4>Address details</h4>

                                    <div className="row">

                                        <div className="input-field col s6">
                                            <input id="company_name" type="text" name="compnayname" className="validate" onChange={this.onChange}
                                                value={this.state.userDetails.compnayname} />
                                            <label htmlFor="company_name" className="active">Company Name </label>
                                            <span className="red-text"></span>
                                        </div>


                                        <div class="input-field col s6 inputselect">
                                            <label for="company_size">Company Size <span className="red-text">*</span></label>
                                            <select name="companySize" id="company_size" value={this.state.userDetails.companySize} style={{ display: "block" }} onChange={this.onChange}>
                                                <option value={this.state.userDetails.companySize}>{this.state.userDetails.companySize} </option>
                                                <option value={`>50`}>{`>50`} </option>
                                                <option value={`51-100`}>{`51-100`} </option>
                                                <option value={`101-150`}>{`101-150`} </option>
                                                <option value={`151-200`}>{`151-200`} </option>
                                                <option value={`201-250`}>{`201-250`} </option>
                                                <option value={`251-300`}>{`251-300`} </option>
                                                <option value={`301 <`}>{`301 <`} </option>
                                            </select>
                                            <span className="red-text error-text">{this.state.errors.companySize}</span>
                                        </div>

                                        <div class="input-field col s6 inputselect">
                                            <label for="country">Country <span className="red-text">*</span></label>
                                            <select name="country" id="country" style={{ display: "block" }} onChange={this.onChangeCountry}>
                                                <option value={this.state.userDetails.country}>{this.state.userDetails.country}</option>
                                                {Countries.map((e) => {
                                                    return <option value={e.states}>{e.country}</option>;
                                                })}
                                            </select>
                                        </div>
                                        <div class="input-field col s6 inputselect">
                                            <label for="state">State <span className="red-text">*</span></label>
                                            <select name="state" id="state" style={{ display: "block" }} onChange={this.onChange}>
                                                <option value={this.state.userDetails.state}>{this.state.userDetails.state}</option>
                                                {this.state.states.map((e, index) => {
                                                    return <option key={index} value={e}>{e}</option>
                                                })}
                                            </select>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="City" type="text" name="city" className="validate" onChange={this.onChange}
                                                value={this.state.userDetails.city} />
                                            <label htmlFor="City" className="active">City <span className="red-text">*</span></label>
                                            <span className="red-text"></span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="addr1" type="text" name="addressline1" className="validate" onChange={this.onChange}
                                                value={this.state.userDetails.addressline1} />
                                            <label htmlFor="addr1" className="active">Address Line 1 <span className="red-text">*</span></label>
                                            <span className="red-text"></span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="addr2" type="text" name="addressline2" className="validate" onChange={this.onChange}
                                                value={this.state.userDetails.addressline2} />
                                            <label htmlFor="addr2" className="active">Address Line 2 <span className="red-text">*</span></label>
                                            <span className="red-text"></span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="pincode" type="text" name="postalcode" className="validate" onChange={this.onChange}
                                                value={this.state.userDetails.postalcode} />
                                            <label htmlFor="pincode" className="active">Postal Code <span className="red-text">*</span></label>
                                            <span className="red-text"></span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="website" type="text" name="website" className="validate" onChange={this.onChange}
                                                value={this.state.userDetails.website} />
                                            <label htmlFor="website" className="active">Website </label>
                                            <span className="red-text"></span>
                                        </div>

                                    </div>
                                </div><div className="col s12 module">
                                    <h4>Bank details</h4>
                                    <div className="row">
                                        <div className="input-field col s6">
                                            <input id="bankname1" type="text" name="bankname" className="validate" onChange={this.onChangeBankDetails}
                                                value={this.state.bankname} />
                                            <label htmlFor="bankname1" className="active">Bank Name </label>
                                            <span className="red-text"></span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="banknum" type="text" name="accountNumber" className="validate" onChange={this.onChangeBankDetails}
                                                value={this.state.accountNumber} />
                                            <label htmlFor="banknum" className="active">Bank Account Number </label>
                                            <span className="red-text"></span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="ifsc" type="text" name="ifscCode" className="validate" onChange={this.onChangeBankDetails}
                                                value={this.state.ifscCode} />
                                            <label htmlFor="ifsc" className="active">IFSC Code </label>
                                            <span className="red-text"></span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="vat" type="text" name="vatnumber" className="validate" onChange={this.onChange}
                                                value={this.state.userDetails.vatnumber} />
                                            <label htmlFor="vat" className="active">VAT Number </label>
                                            <span className="red-text"></span>
                                        </div>

                                        <div class="input-field col s6 inputselect">
                                            <label for="country">Currency</label>
                                            <select name="currency" id="currency" style={{ display: "block" }} onChange={this.onChange}>
                                                <option value={this.state.userDetails.currency}>{this.state.userDetails.currency} </option>
                                                {Currency.map((currency) => {
                                                    return <option value={currency.cc}> {currency.cc}, {currency.name}</option>;
                                                })}
                                            </select>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="partrefcode" type="text" name="partnerrefCode" className="validate" onChange={this.onChange}
                                                value={this.state.userDetails.partnerrefCode} />
                                            <label htmlFor="partrefcode" className="active">Partner Ref Code </label>
                                            <span className="red-text"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 center">
                                <button className="btn btn-large waves-effect waves-light hoverable black accent-3" onClick={this.handleSubmit}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile
