import React, { Component } from 'react'
import axios from '../axios/index'

export class Customers extends Component {
    constructor(props) {
        super(props)
    
        this.state= {
            customerDetials: []
          }
    }

    componentDidMount() {
        axios.get(`/api/customers/filtercustomers`)
        .then(res => {
              console.log("res anil", res)
            const customerDetials = res.data;
            this.setState({ customerDetials });
          })
      }
    
    render() {
        return (
            <div style={{marginTop:'50px'}}>
                <div className="container grid-container">
                    <div className="row nav-widgets" style={{marginBottom:"0px"}}>
                        <div className="col s12">
                            <h4 style={{marginTop:"10px"}}>Customers</h4>
                        </div>
                    </div>
                    <div className="nav-widgets">
                        <div className="col s2">
                            <select className="browser-default dropdown-sel">
                                <option >Select a Category</option>
                            </select>
                        </div>
                        <button className="btn-transparent">Filter</button>
                        <div className="searchbox">
                            <input autocomplete="off" placeholder="Search by Service Request Id"></input>
                            <label>10 results</label>
                        </div>
                        <button className="btn-transparent"> sort</button>
                    </div>

                    {this.state.customerDetials.map((customer) => {
                        return (
                            <div key={customer.id}>
                                <div className="cards" style={{ marginTop: "16px" }}>
                                    <div className="leftBlock">

                                        <h6>{customer.firstname}</h6>
                                        <p>{customer.email}</p>
                                        <p>{customer.addressline1}  {customer.addressline2}</p>
                                        <ul>
                                            <li>{customer.state}</li>
                                            <li>{customer.country}</li>
                                        </ul>
                                    </div>
                                    <div className="rightBlock">
                                        <ul>
                                            <li>
                                                <button className="custombtn">Edit</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )}
                    <div class="pagination">
                        <button class="disabled" disabled="">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-8 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                <path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z">
                                </path>
                            </svg>
                        </button>
                        <button class="activepage">1</button>
                        <button class="">2</button>
                        <button class="">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                <path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z">
                                </path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Customers
