import React, { Component } from 'react'
import axios from '../axios/index';
import { Countries } from '../utils/Countries';
import { Currency } from '../utils/Currency';

export class AddCustomer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: "",
            password: "",
            password2: "",
            fkcustomerid: "",
            compnayName: "",
            firstName: "",
            lastName: "",
            addressline1: "",
            addressline2: "",
            postalcode: "",
            city: "",
            state: "",
            country: "",
            loggedinIP: "",
            website: "",
            accountNumber: "",
            bankname: "",
            ifscCode: "",
            vatnumber: "",
            companySize: "",
            currency: "",
            partnerrefCode: " ",
            states: [],
            userDetails:[],
            fields: {},
            errors: {}
        }
    }
    // componentDidMount(){
    //     axios.get('/api/customers/profile?email=customer@gmail.com' )
    //     .then(res =>{
    //         this.setState({
    //             userDetails: res.data
    //         })
    //     })
    // }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if(!fields["firstname"]){
           formIsValid = false;
           errors["firstname"] = "First name cannot be empty";
        }
        if(!fields["lastname"]){
           formIsValid = false;
           errors["lastname"] = "Last name cannot be empty";
        }
        if(!fields["email"]){
           formIsValid = false;
           errors["email"] = " Email cannot be empty";
        }
        if(!fields["password2"]){
           formIsValid = false;
           errors["password2"] = "Confirm password cannot be empty";
        }
        if(!fields["addressline1"]){
           formIsValid = false;
           errors["addressline1"] = "Adrress field cannot be empty";
        }
        if(!fields["addressline2"]){
           formIsValid = false;
           errors["addressline2"] = "Adrress field cannot be empty";
        }
        if(!fields["companySize"]){
           formIsValid = false;
           errors["companySize"] = "Company size field cannot be empty";
        }
        if(!fields["country"]){
           formIsValid = false;
           errors["country"] = "Cannot be empty";
        }
        if(!fields["state"]){
           formIsValid = false;
           errors["state"] = "State cannot be empty";
        }
        if(!fields["city"]){
           formIsValid = false;
           errors["city"] = "City cannot be empty";
        }
        if(!fields["postalcode"]){
           formIsValid = false;
           errors["postalcode"] = "Postal code cannot be empty";
        }

        
        if(fields["password"].match(/^(?=.*[0-9])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,30}$/)) {
           
        } else {
            formIsValid = false;
             errors["password"] = "password criteria not matching(1 uppercase, 1 lowercase, 1 number and it should contain 6-30 digits";
        }
        // if(fields["password"].match(regularExpression)){
        //     formIsValid = false;
        //     errors["password"] = "password cannot be empty";
        //  }
  
        // if(typeof fields["name"] !== "undefined"){
        //    if(!fields["name"].match(/^[a-zA-Z]+$/)){
        //       formIsValid = false;
        //       errors["name"] = "Only letters";
        //    }        
        // }
   
        //Email
    //     if(!fields["email"]){
    //        formIsValid = false;
    //        errors["email"] = "Cannot be empty";
    //     }
  
        if(fields["email"]){
           let lastAtPos = fields["email"].lastIndexOf('@');
           let lastDotPos = fields["email"].lastIndexOf('.');

           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
              formIsValid = false;
              errors["email"] = "Email is not valid";
            }
       }  

       this.setState({errors: errors});
       return formIsValid;
   }

   
    
    onChange = (evt) => {
        let errors = this.state.errors
        delete errors[evt.target.name];
        let fields = this.state.fields;
            fields[evt.target.name] = evt.target.value;        
            this.setState({fields,errors});
        this.setState({
            [evt.target.name]:evt.target.value
        })
        this.handleValidation()
    }

    onChangeCountry = (e) => {
        let errors = this.state.errors
        delete errors[e.target.name];
        let fields = this.state.fields;
            fields[e.target.name] = e.target.value;        
            this.setState({fields,errors});
        const i = e.target.selectedIndex;
        const optionValue = e.target.childNodes[i]
        const countryValue = optionValue.getAttribute('name')
        const data = e.target.value.split(",")
        this.setState({
            states: data,
            country: countryValue
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.handleValidation()
        const customer = {
                email:this.state.email,
                password:this.state.password,
                password2:this.state.password2,
                fkcustomerid:this.state.fkcustomerid,
                companyName:this.state.compnayName,
                firstName:this.state.firstname,
                lastName:this.state.lastname,
                addressline1:this.state.addressline1,
                addressline2:this.state.addressline2,
                postalcode:this.state.postalcode,
                city:this.state.city,
                state:this.state.state,
                country:this.state.country,
                loggedinIP:this.state.loggedinIP,
                website:this.state.website,
                accountNumber:this.state.accountNumber,
                bankname:this.state.bankname,
                ifscCode:this.state.ifscCode,
                vatnumber:this.state.vatnumber,
                companySize:this.state.companySize,
                currency:this.state.currency,
                partnerrefCode:this.state.partnerrefCode
        }
       if(Object.keys(this.state.errors).length !== 0 && this.state.errors.constructor === Object) {
        axios.post('/api/customers/register', customer )
       
        .then(res =>{
            console.log(res)
            console.log(res.data)
        })
    }
    }

    render() {
        const {errors,fields} = this.state
        let enableSubmitBtn= false
        if(Object.keys(errors).length !== 0 || Object.keys(fields).length === 0) {
            enableSubmitBtn = true
        }
         return (
            <div>
                <div className="container">
                    <div className="row" style={{ marginBottom: "0px" }}>
                        <div className="container grid-container" style={{ marginTop: "60px" }}>
                            <div className="col s12">
                                <h4 style={{ marginTop: "10px" }}>Add Customer</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <form className="col s12">
                            <div className="row">
                                <div className="col s12 module">
                                    {/* <div className="row profile-container" style={{ display: "none" }}>
                                        <div className="input-field col s12 profile-pic-container">

                                            <img id="profile-pic" />
                                            <input type="file" name="file" title="Update profile picture" onChange={this.onChangeHandler} style={{ width: "100%", height: "100%", position: "absolute", zIndex: 101 }} />
                                            <button className="right edit-btn" title="Update profile picture"></button>
                                            <button className="right del-btn" onClick={this.removeProfilePic} title="Remove profile picture"></button>
                                        </div>
                                        <div className="profile-intro-text">These details will be provide to our Service Managers and Potential Clients you need to fully complete your profile so we can allocate leads for you and/or your company to place bids.
                                        </div>
                                        <div className="input-field col s12 profile-pic-screenname">
                                            <h5 style={{ textTransform: "uppercase" }}>User Name</h5>
                                        </div>
                                    </div> */}


                                    <div className="row">
                                        <div className="input-field col s6">
                                            <input id="first_name" type="text" name="firstname" className="validate" onChange={this.onChange}
                                            value={this.state.firstname} required/>

                                            <label htmlFor="first_name" className="active">First Name <span className="red-text">*</span></label>
                                            <span className="red-text error-text">{this.state.errors.firstname}</span>
                                        </div>
                                        <div className="input-field col s6">
                                            <input id="last_name" type="text" name="lastname" className="validate" onChange={this.onChange}
                                            value={this.state.lastname}  required/>

                                            <label htmlFor="last_name" className="active">Last Name <span className="red-text">*</span></label>
                                            <span className="red-text error-text">{this.state.errors.lastname}</span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="email" type="email" name="email" className="validate" onChange={this.onChange}
                                            value={this.state.email}  required/>

                                            <label htmlFor="email" className="active">Email Id <span className="red-text">*</span></label>
                                            <span className="red-text error-text">{this.state.errors.email}</span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="password" type="password" name="password" className="validate" onChange={this.onChange}
                                                value={this.state.password} required aria-describedby="passwordHelpBlock"/>
                                            <label htmlFor="password" className="active">Password <span className="red-text">*</span></label>
                                            <span className="red-text error-text">{this.state.errors.password}</span>
                                            {/* <div id="passwordHelpBlock" muted style={{fontSize:"12px"}}>
                                                Your password must be 6-30 characters long, at least 1 number, 1 uppercase and 1 lowercase letter.
                                            </div > */}
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="password2" type="password" name="password2" className="validate" onChange={this.onChange}
                                                value={this.state.password2} required/>
                                            <label htmlFor="password2" className="active">Confirm Password<span className="red-text">*</span></label>
                                            <span className="red-text error-text">{this.state.errors.password2}</span>
                                        </div>
                                        {/* <div>
                                            {this.state.password !== this.state.password2 ? <p className="red-text">Passwords are not matching</p>:null}
                                        </div> */}

                                    </div>
                                </div>
                                <div className="col s12 module">
                                    <h4>Address details</h4>

                                    <div className="row">

                                        <div className="input-field col s6">
                                            <input id="company_name" type="text" name="compnayName" className="validate" onChange={this.onChange}
                                                value={this.state.compnayName} />

                                            <label htmlFor="company_name" className="active">Company Name </label>
                                            <span className="red-text error-text"></span>
                                        </div>

                                        <div class="input-field col s6 inputselect">
                                            <label for="company_size">Company Size <span className="red-text">*</span></label>
                                            <select name="companySize" id="company_size" style={{ display: "block" }} onChange={this.onChange}>
                                            <option>Select size </option>
                                            <option value={`>50`}>{`>50`} </option>
                                            <option value={`51-100`}>{`51-100`} </option>
                                            <option value={`101-150`}>{`101-150`} </option>
                                            <option value={`151-200`}>{`151-200`} </option>
                                            <option value={`201-250`}>{`201-250`} </option>
                                            <option value={`251-300`}>{`251-300`} </option>
                                            <option value={`301 <`}>{`301 <`} </option>
                                                
                                            </select>
                                            <span className="red-text error-text">{this.state.errors.companySize}</span>
                                        </div>
                                        
                                        <div className="input-field col s6">
                                            <input id="addr1" type="text" name="addressline1" className="validate" onChange={this.onChange}
                                                value={this.state.addressline1}/>
                                            <label htmlFor="addr1" className="active">Address Line 1 <span className="red-text">*</span></label>
                                            <span className="red-text error-text">{this.state.errors.addressline1}</span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="addr2" type="text" name="addressline2" className="validate" onChange={this.onChange}
                                                value={this.state.addressline2} />
                                            <label htmlFor="addr2" className="active">Address Line 2 <span className="red-text">*</span></label>
                                            <span className="red-text error-text">{this.state.errors.addressline2}</span>
                                        </div>

                                        <div class="input-field col s6 inputselect">
                                            <label for="country1">Country <span className="red-text">*</span></label>

                                            <select name="country" id="country1" style={{ display: "block" }} onChange={this.onChangeCountry}>
                                            <option>Select Country</option>
                                                {Countries.map((e) => {
                                                    return <option name={e.country} value={e.states}>{e.country}</option>;
                                                })}
                                            </select>
                                            <span className="red-text error-text">{this.state.errors.country}</span>
                                        </div>
                                        <div class="input-field col s6 inputselect">
                                            <label for="state">State <span className="red-text">*</span></label>
                                            <select name="state" id="state" style={{ display: "block" }} onChange={this.onChange}>
                                              <option>Select State</option>
                                                {this.state.states.map((e,index)=>{
                                                    return <option key={index} value={e}>{e}</option>
                                                })}
                                            </select>
                                            <span className="red-text error-text">{this.state.errors.state}</span>
                                        </div>
                                       
                                        <div className="input-field col s6">
                                            <input id="City" type="text" name="city" className="validate" onChange={this.onChange}
                                                value={this.state.city} />
                                            <label htmlFor="City" className="active">City <span className="red-text">*</span></label>
                                            <span className="red-text error-text">{this.state.errors.city}</span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="pincode" type="text" name="postalcode" className="validate" onChange={this.onChange}
                                                value={this.state.postalcode} />
                                            <label htmlFor="pincode" className="active">Postal Code <span className="red-text">*</span></label>
                                            <span className="red-text error-text">{this.state.errors.postalcode}</span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="website" type="text" name="website" className="validate" onChange={this.onChange}
                                                value={this.state.website} />
                                            <label htmlFor="website" className="active">Website </label>
                                            <span className="red-text error-text"></span>
                                        </div>

                                    </div>
                                </div><div className="col s12 module">
                                    <h4>Bank details</h4>
                                    <div className="row">
                                        <div className="input-field col s6">
                                            <input id="bankname" type="text" name="bankname" className="validate" onChange={this.onChange}
                                                value={this.state.bankname} />
                                            <label htmlFor="bankname" className="active">Bank Name </label>
                                            <span className="red-text"></span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="banknum" type="text" name="accountNumber" className="validate" onChange={this.onChange}
                                                value={this.state.accountNumber} />
                                            <label htmlFor="banknum" className="active">Bank Account Number </label>
                                            <span className="red-text"></span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="ifsc" type="text" name="ifscCode" className="validate" onChange={this.onChange}
                                                value={this.state.ifscCode} />
                                            <label htmlFor="ifsc" className="active">IFSC Code </label>
                                            <span className="red-text"></span>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="vat" type="text" name="vatnumber" className="validate" onChange={this.onChange}
                                                value={this.state.vatnumber} />
                                            <label htmlFor="vat" className="active">VAT Number </label>
                                            <span className="red-text"></span>
                                        </div>

                                        <div class="input-field col s6 inputselect">
                                            <label for="currency">Currency</label>
                                            <select name="currency" id="currency" style={{ display: "block" }} onChange={this.onChange}>
                                            <option>Select Currency </option>
                                                {Currency.map((currency) => {
                                                    return <option  value={currency.cc}> {currency.cc}, {currency.name}</option>;
                                                })}
                                            </select>
                                        </div>

                                        <div className="input-field col s6">
                                            <input id="partrefcode" type="text" name="partnerrefCode" className="validate" onChange={this.onChange}
                                                value={this.state.partnerrefCode} />
                                            <label htmlFor="partrefcode" className="active">Partner Ref Code </label>
                                            <span className="red-text"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 center">
                                <button className="btn btn-large waves-effect waves-light hoverable black accent-3" disabled={enableSubmitBtn} onClick={this.handleSubmit}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddCustomer
