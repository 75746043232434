import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import React, { Component } from 'react'
import logo from '../layouts/logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (

            <div className="navbar-fixed">
                <nav class="z-depth-0">
                    <div class="nav-wrapper white"></div>
                </nav>
                <div className="header-nav">
                    <div className="row">
                        <div className="col s12" style={{position:"fixed",zIndex:99}}>
                            <div className="menu-wrapper col s12">
                                <a href="#" className="col brand-logo black-text">
                                    <img src={logo} alt="Logo" />
                                </a>
                                <div className="col s6 right " style={{ textAlign: "right" }}>
                                    <div class="dropdown">
                                        <button class="dropbtn" style={{ padding: "10px" }}>Welcome User {" "}
                                        <FontAwesomeIcon icon={faAngleDown} />
                                        </button>
                                        <div class="dropdown-content">
                                            <a href="#">Dashboard</a>
                                            {/* <a href="#">Profile</a> */}
                                            <Link to="/editProfile">
                                                Profile
                                            </Link>
                                            <a href="#">Logout</a>
                                        </div>
                                    </div>
                                    <button className="menu-nav-button" >
                                    Messages
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="subnav">
                            <div className="row">
                                <div className="menu-wrapper left col s12">
                                    <div style={{marginTop:"5px"}}>
                                        <Link to="/customers">Customers</Link>
                                        {/* <a className="col active_link_two" href="#">Customers</a> */}
                                        {/* <a className="col " href="#"></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            // <div>
            //     
            // </div>
        )
    }
}

export default Menu
